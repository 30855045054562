import { Grid, Typography } from '@mui/material';
import { Outlet } from 'react-router';
import { SystemPageTabs } from '~/pages/layout/components/Navigation/SystemPageTabs';
import NotFound from '~/shared/components/NotFoundPage';
import { SystemSelect } from '~/shared/components/SystemSelect/SystemSelect';
import { useOptionalSystem } from '~/shared/hooks/system-hooks';
import { useRequiredSystems } from '~/shared/hooks/systems-hooks';

export function SystemPageLayout() {
  const currentSystem = useOptionalSystem();
  const systems = useRequiredSystems();

  if (!currentSystem) {
    return <NotFound />;
  }

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid
          item
          sx={{
            mb: {
              xs: 2,
              sm: 0,
            },
          }}
          xs={12}
          sm={6}
          md={7}
          xl={9}
        >
          <Typography
            variant="display-m"
            sx={{
              wordBreak: 'break-word',
            }}
          >
            {currentSystem.title}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={5} xl={3}>
          <SystemSelect
            selectedSystem={currentSystem.title}
            systems={systems}
          />
        </Grid>

        <Grid item xs={12}>
          <SystemPageTabs />
        </Grid>
      </Grid>

      <Outlet />
    </>
  );
}
