import {
  Metric,
  MetricType,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import { TFunction } from 'i18next';
import {
  ColorType,
  DataGroupingApproximationValue,
  SeriesAreaOptions,
  SeriesColumnOptions,
  SeriesLineOptions,
} from 'highcharts';
import {
  DataPoint,
  DataPointWithNull,
  mapDataPoint,
} from '~/shared/models/metrics/metrics-utils';
import { assertIsDefined } from '~/types/assert-type';
import {
  getSensorReadingLabel,
  getUnit,
  mapMetricValue,
  roundNumericMetric,
  Unit,
} from '~/shared/models/metrics/metric-conversions';

const MINUTE_MS = 60 * 1_000;

function getIntervalCount(differenceMs: number) {
  return Math.round(differenceMs / (10 * MINUTE_MS));
}

type ChartTooltipProps = {
  dataPoint: number | undefined;
  seriesColor: ColorType | undefined;
  seriesName: string;
  t: TFunction;
};

type ChartType = 'column' | 'line' | 'area';

type ProductMetricChartSeriesOptions =
  | SeriesAreaOptions
  | SeriesLineOptions
  | SeriesColumnOptions;

export class ChartMetricModel {
  readonly unit: Unit | undefined;

  readonly chartType: ChartType;

  readonly type: MetricType;

  readonly step?: 'center';

  readonly dataApproximation: DataGroupingApproximationValue;

  readonly chartData: DataPointWithNull[] = [];

  readonly resourceName: string;

  readonly axisGroup: string;

  readonly valueRange: {
    min?: number;
    max?: number;
  };

  constructor(metric: Metric) {
    this.resourceName = metric.resourceName;

    this.type = metric.type;

    this.unit = getUnit(this.type);

    this.chartType = this.getChartType();

    this.axisGroup = this.getMetricAxisGroup();

    this.step = this.getStepOptions();

    this.dataApproximation = this.getDataGroupingApproximation();

    this.valueRange = this.getValueRange();

    metric.dataPoints
      .map((dataPoint) => mapDataPoint(dataPoint))
      .forEach((dataPoint, i, data) => {
        this.addDataPoint(data[i - 1], dataPoint);
      });
  }

  private addDataPoint(
    previousDataPoint: DataPoint | undefined,
    dataPoint: DataPoint,
  ) {
    if (previousDataPoint) {
      this.addMissingData(previousDataPoint[0], dataPoint[0]);
    }
    this.chartData.push(dataPoint);
  }

  private addMissingData(timeA: number, timeB: number) {
    const differenceMs = timeB - timeA;
    const intervalCount = getIntervalCount(differenceMs);
    if (!intervalCount) {
      return;
    }
    const gapSize = differenceMs / intervalCount;
    const missingValueCount = intervalCount - 1;
    for (const i of Array(missingValueCount).keys()) {
      const timeAOffset = (i + 1) * gapSize;
      this.chartData.push([timeA + timeAOffset, null]);
    }
  }

  getChartTooltip = ({
    dataPoint,
    seriesColor,
    seriesName,
    t,
  }: ChartTooltipProps) => {
    assertIsDefined(dataPoint);
    let value = mapMetricValue(t, this.type, dataPoint);

    if (typeof value === 'number') {
      value = `${roundNumericMetric(this.type, dataPoint)} ${this.unit}`;
    }

    return `
            <span>
              <span style="color:${seriesColor}">\u25CF</span>
                ${seriesName}: <b>${value}</b>
            </span>`;
  };

  getChartLabel({ t, color }: { t: TFunction; color: string }) {
    const label = getSensorReadingLabel(t, this.type);

    const text = `<span style="color: #353750; font-size: 16px; line-height: 24px">${label}</span>`;

    return `<span style="color:${color}; font-size: 24px">\u25CF  ${text}</span>`;
  }

  yAxisLabelFormatter = (t: TFunction, value: number) => {
    const label = mapMetricValue(t, this.type, value);

    if (typeof label === 'number') {
      return `${value} ${this.unit ?? ''}`;
    }

    return label;
  };

  private getChartType = (): 'column' | 'line' | 'area' => {
    switch (this.type) {
      case MetricType.PRECIPITATION_MM:
        return 'column';
      case MetricType.TAP_ON:
      case MetricType.PUMP_STATE_ON:
      case MetricType.VALVE_STATE_ON:
        return 'area';
      default:
        return 'line';
    }
  };

  private getMetricAxisGroup(): string {
    switch (this.type) {
      case MetricType.AIR_TEMPERATURE_CELSIUS:
      case MetricType.WATER_TEMPERATURE_CELSIUS:
        return '1';
      case MetricType.WATER_HEIGHT_MM:
      case MetricType.WATER_HEIGHT_DIFFERENCE_MM:
      case MetricType.VALVE_HEIGHT_MM:
        return '2';
      case MetricType.PRECIPITATION_MM:
        return '3';
      case MetricType.BATTERY_PERCENTAGE:
      case MetricType.HUMIDITY_PERCENTAGE:
        return '4';
      case MetricType.WIND_SPEED_METER_PER_SECOND:
      case MetricType.WIND_GUST_SPEED_METER_PER_SECOND:
        return '5';
      case MetricType.CELLULAR_RSSI_DBM:
        return '6';
      case MetricType.TAP_ON:
      case MetricType.VALVE_STATE_ON:
        return '7';
      case MetricType.FLOW_DIFFERENCE_L:
        return '8';
      case MetricType.FLOW_RATE_L_SECOND:
        return '9';
      case MetricType.PUMP_STATE_ON:
        return '10';
      case MetricType.WIND_DIRECTION_DEGREES:
      case MetricType.WIND_GUST_DIRECTION_DEGREES:
        return '11';
      case MetricType.SYSTEM_MODE:
        return '12';
      default:
        throw new Error(`Unsupported metric type ${this.type}`);
    }
  }

  private getStepOptions = (): 'center' | undefined => {
    switch (this.type) {
      case MetricType.TAP_ON:
      case MetricType.PUMP_STATE_ON:
      case MetricType.VALVE_STATE_ON:
        return 'center';
      default:
        return undefined;
    }
  };

  private getDataGroupingApproximation(): DataGroupingApproximationValue {
    switch (this.type) {
      case MetricType.PRECIPITATION_MM:
        return 'sum';
      case MetricType.TAP_ON:
      case MetricType.PUMP_STATE_ON:
      case MetricType.VALVE_STATE_ON:
        return 'high';
      case MetricType.VALVE_HEIGHT_MM:
        return 'low';
      default:
        return 'average';
    }
  }

  private getValueRange() {
    switch (this.type) {
      case MetricType.TAP_ON:
      case MetricType.PUMP_STATE_ON:
      case MetricType.VALVE_STATE_ON:
        return {
          min: 0,
          max: 1.1,
        };
      default:
        return {};
    }
  }

  createSerie(
    t: TFunction,
    yIndex: number,
    color: string,
  ): ProductMetricChartSeriesOptions {
    const { getChartTooltip } = this;
    const dataGrouping = {
      approximation: this.dataApproximation,
      groupPixelWidth: 4,
    };

    const series: ProductMetricChartSeriesOptions = {
      type: this.chartType,
      dataGrouping,
      data: this.chartData,
      color,
      connectNulls: false,
      showInNavigator: true,
      dashStyle: 'Solid',
      marker: {
        enabled: false,
      },
      yAxis: yIndex,
      step: this.step,
      tooltip: {
        pointFormatter() {
          return getChartTooltip({
            dataPoint: this.y,
            seriesColor: this.series.color,
            seriesName: this.series.name,
            t,
          });
        },
      },
      name: getSensorReadingLabel(t, this.type),
    };

    return series;
  }
}
