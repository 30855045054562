import { DeviceDetailsModel } from '~/pages/Advanced/models/DeviceDetailsModel';
import { getDeviceDetails } from '~/services/api/fetchers/deviceDetails.api';
import { makeQuery } from '~/shared/api/internal/utils';

export const getDeviceDetailsQuery = (resourceName: string) =>
  makeQuery({
    queryKey: ['deviceDetails', resourceName],
    queryFn: async () => {
      const deviceDetailsResponse = await getDeviceDetails(resourceName);
      return new DeviceDetailsModel(deviceDetailsResponse);
    },
  });
