/* eslint-disable no-console */
export const DEBUG_MODE_STORAGE_KEY = 'grpc_debug_mode_enabled';

export function debugMode(enable: boolean) {
  if (enable) {
    localStorage.setItem(DEBUG_MODE_STORAGE_KEY, 'true');
    console.log('gRPC Debug Mode Enabled');
  } else {
    localStorage.removeItem(DEBUG_MODE_STORAGE_KEY);
    console.log('gRPC Debug Mode Disabled');
  }
}

if (localStorage.getItem(DEBUG_MODE_STORAGE_KEY) === 'true') {
  console.log('gRPC Debug Mode is active');
}
