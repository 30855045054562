import { wrapCreateBrowserRouterV7 } from '@sentry/react';
import React from 'react';
import { createBrowserRouter, redirect, RouteObject } from 'react-router';
import { advancedLoader } from '~/pages/Advanced/AdvancedPage.loader';
import { dashboardLoader } from '~/pages/Dashboard/DashboardPage.loader';
import { downloadsLoader } from '~/pages/Downloads/DownloadsPage.loader';
import { forgetPasswordAction } from '~/pages/ForgotPassword/ForgotPasswordPage.action';
import { resetPasswordAction } from '~/pages/PasswordConfirm/ResetPasswordPage.action';
import { createPasswordAction } from '~/pages/PasswordConfirm/CreatePasswordPage.action';
import { issuesLoader } from '~/pages/Issues/IssuesPage.loader';
import { AppLayout, rootLoader } from '~/pages/layout/AppLayout';
import { AuthLayout } from '~/pages/layout/AuthLayout';
import { RouterProviders } from '~/pages/layout/Providers';
import {
  metricsLoader,
  metricsSystemsLoader,
} from '~/pages/Metrics/MetricsPage.loader';
import { profilePageLoader } from '~/pages/ProfilePage/ProfilePage.loader';
import { signInAction } from '~/pages/SignIn/SignInPage.action';
import { sensorReadingsLoader } from '~/pages/System/components/ProductInfoSidebar/ProductInfoSidebarSection.loader';
import { systemLoader } from '~/pages/System/SystemPage.loader';
import { systemCreatePageLoader } from '~/pages/SystemCreate/SystemCreatePage.loader';
import { systemDetailsPageLoader } from '~/pages/SystemCreate/SystemDetailsPage.loader';
import { systemDesignLoader } from '~/pages/SystemDesign/SystemDesignPage.loader';
import { systemsListLoader } from '~/pages/SystemsList/SystemsListPage.loader';
import { authService } from '~/services/auth/auth-service';
import { queryClient } from '~/shared/api/query-client';
import { createRoutes, createSystemRoute } from '~/shared/models/create-routes';
import { termsPageLoader } from './TermsConditions/TermsConditionsPage.loader';
import { SystemPageLayout } from '~/pages/layout/SystemPageLayout';
import { resevoirDynamicInfoLoader } from '~/pages/ReservoirDynamicInfo/ReservoirDynamicInfoPage.loader';
import { systemUsersLoader } from '~/pages/SystemUsers/SystemUsersPage.loader';
import { systemNotesLoader } from '~/pages/SystemNotes/SystemNotesPage.loader';
import { usersLoader } from '~/pages/Users/UsersPage.loader';
import { ensureNotLoggedIn } from '~/shared/auth/auth-utils';
import { reservoirParametersSidebarSectionLoader } from '~/pages/System/components/ReservoirInfoSidebar/ReservoirParametersSidebarSection.loader';
import { billOfMaterialsPageLoader } from '~/pages/BillOfMaterials/BillOfMaterialsPage.loader';
import { statisticsPageLoader } from '~/pages/StatisticsPage/StatisticsPage.loader';

const AdvancedPage = React.lazy(() => import('~/pages/Advanced/AdvancedPage'));
const DashboardPage = React.lazy(
  () => import('~/pages/Dashboard/DashboardPage'),
);
const DownloadsPage = React.lazy(
  () => import('~/pages/Downloads/DownloadsPage'),
);
const ErrorPage = React.lazy(() => import('~/pages/ErrorPage/ErrorPage'));
const MetricsPage = React.lazy(() => import('~/pages/Metrics/MetricsPage'));
const NotFoundPage = React.lazy(
  () => import('~/shared/components/NotFoundPage'),
);
const IssuesPage = React.lazy(() => import('~/pages/Issues/IssuesPage'));
const ProductInfoSidebarSection = React.lazy(
  () =>
    import(
      '~/pages/System/components/ProductInfoSidebar/ProductInfoSidebarSection'
    ),
);
const ReservoirParametersSidebarSection = React.lazy(
  () =>
    import(
      '~/pages/System/components/ReservoirInfoSidebar/ReservoirParametersSidebarSection'
    ),
);
const ReservoirDynamicInfoPage = React.lazy(
  () => import('~/pages/ReservoirDynamicInfo/ReservoirDynamicInfoPage'),
);
const ReservoirDesignSection = React.lazy(
  () =>
    import('~/pages/SystemDesign/components/Reservoir/ReservoirDesignSection'),
);
const DrainDesignSection = React.lazy(
  () => import('~/pages/SystemDesign/components/Drain/DrainDesignSection'),
);

const DrainParametersSidebarSection = React.lazy(
  () =>
    import(
      '~/pages/System/components/DrainParametersSidebarSection/DrainParametersSidebarSection'
    ),
);

const ProductDesignSection = React.lazy(
  () => import('~/pages/SystemDesign/components/Product/ProductDesignSection'),
);
const ProfilePage = React.lazy(() => import('~/pages/ProfilePage/ProfilePage'));
const SignInPage = React.lazy(() => import('~/pages/SignIn/SignInPage'));
const SupportPage = React.lazy(() => import('~/pages/Support/SupportPage'));
const SystemsPage = React.lazy(() => import('~/pages/System/SystemPage'));
const SystemCreatePage = React.lazy(
  () => import('~/pages/SystemCreate/SystemCreatePage'),
);
const SystemDetailsPage = React.lazy(
  () => import('~/pages/SystemCreate/SystemDetailsPage'),
);

const SystemsDesignPage = React.lazy(
  () => import('~/pages/SystemDesign/SystemDesignPage'),
);
const SystemsListPage = React.lazy(
  () => import('~/pages/SystemsList/SystemsListPage'),
);
const UnAuthorizedPage = React.lazy(
  () => import('~/pages/UnAuthorizedPage/UnAuthorizedPage'),
);
const TermsPage = React.lazy(
  () => import('~/pages/TermsConditions/TermsConditionsPage'),
);

const ForgotPasswordPage = React.lazy(
  () => import('~/pages/ForgotPassword/ForgotPasswordPage'),
);
const CreatePasswordPage = React.lazy(
  () => import('~/pages/PasswordConfirm/CreatePasswordPage'),
);
const ResetPasswordPage = React.lazy(
  () => import('~/pages/PasswordConfirm/ResetPasswordPage'),
);
const DrawerContentSection = React.lazy(
  () => import('~/pages/layout/components/Navigation/DrawerContentSection'),
);
const SystemUsersPage = React.lazy(
  () => import('~/pages/SystemUsers/SystemUsersPage'),
);
const SystemNotesPage = React.lazy(
  () => import('~/pages/SystemNotes/SystemNotesPage'),
);
const UsersPage = React.lazy(() => import('~/pages/Users/UsersPage'));

const AddUserPage = React.lazy(() => import('~/pages/Users/AddUserPage'));

const EditUserPage = React.lazy(() => import('~/pages/Users/EditUserPage'));

const BillOfMaterialsPage = React.lazy(
  () => import('~/pages/BillOfMaterials/BillOfMaterialsPage'),
);

const StatisticsPage = React.lazy(
  () => import('~/pages/StatisticsPage/StatisticsPage'),
);

export const routesConfig: RouteObject[] = [
  {
    element: <RouterProviders />,
    errorElement: <ErrorPage />,
    hydrateFallbackElement: <AppLayout />,
    children: [
      {
        element: <AuthLayout />,
        children: [
          {
            path: createRoutes.SignIn,
            element: <SignInPage />,
            action: signInAction(authService),
            loader: async () => await ensureNotLoggedIn(),
          },
          {
            path: createRoutes.ForgotPassword,
            element: <ForgotPasswordPage />,
            errorElement: <ForgotPasswordPage />,
            action: forgetPasswordAction(authService),
            loader: async () => await ensureNotLoggedIn(),
          },
          {
            path: createRoutes.ForgotPasswordConfirm,
            element: <ResetPasswordPage />,
            errorElement: <ResetPasswordPage />,
            action: resetPasswordAction(authService),
            loader: async () => await ensureNotLoggedIn(),
          },
          {
            path: createRoutes.CreatePassword,
            element: <CreatePasswordPage />,
            errorElement: <CreatePasswordPage />,
            action: createPasswordAction(),
            loader: async () => await ensureNotLoggedIn(),
          },
        ],
      },
      {
        element: (
          <AppLayout
            renderDrawerContent={(onClose) => (
              <DrawerContentSection onClose={onClose} />
            )}
          />
        ),
        loader: rootLoader(queryClient),
        children: [
          {
            errorElement: <ErrorPage />,
            children: [
              {
                path: '/',
                loader: () => redirect(createSystemRoute()),
              },
              {
                element: <SystemPageLayout />,
                children: [
                  {
                    path: createRoutes.Downloads,
                    element: <DownloadsPage />,
                    loader: downloadsLoader(queryClient),
                  },
                  {
                    path: createRoutes.Dashboard,
                    element: <DashboardPage />,
                    loader: dashboardLoader(queryClient),
                  },
                  {
                    path: createRoutes.System,
                    element: <SystemsPage />,
                    loader: systemLoader(queryClient),
                    children: [
                      {
                        path: createRoutes.Product,
                        element: <ProductInfoSidebarSection />,
                        loader: sensorReadingsLoader(queryClient),
                      },
                      {
                        path: createRoutes.Reservoir,
                        element: <ReservoirParametersSidebarSection />,
                        loader:
                          reservoirParametersSidebarSectionLoader(queryClient),
                      },
                      {
                        path: createRoutes.Drain,
                        element: <DrainParametersSidebarSection />,
                      },
                    ],
                  },
                  {
                    path: createRoutes.SystemDetails,
                    element: <SystemDetailsPage />,
                    loader: systemDetailsPageLoader(queryClient),
                  },
                  {
                    path: createRoutes.SystemUsers,
                    element: <SystemUsersPage />,
                    loader: systemUsersLoader(queryClient),
                  },
                  {
                    path: createRoutes.SystemNotes,
                    element: <SystemNotesPage />,
                    loader: systemNotesLoader(queryClient),
                  },
                  {
                    path: createRoutes.SystemDesign,
                    loader: systemDesignLoader(queryClient),
                    element: <SystemsDesignPage />,
                    children: [
                      {
                        path: createRoutes.Reservoir,
                        element: <ReservoirDesignSection />,
                      },
                      {
                        path: createRoutes.Drain,
                        element: <DrainDesignSection />,
                      },
                      {
                        path: createRoutes.Product,
                        element: <ProductDesignSection />,
                      },
                    ],
                  },
                  {
                    path: createRoutes.BOM,
                    element: <BillOfMaterialsPage />,
                    loader: billOfMaterialsPageLoader(queryClient),
                  },
                ],
              },
              {
                path: createRoutes.Users,
                element: <UsersPage />,
                loader: usersLoader(queryClient),
              },
              {
                path: createRoutes.AddUser,
                element: <AddUserPage />,
                loader: usersLoader(queryClient),
              },
              {
                path: createRoutes.EditUser,
                element: <EditUserPage />,
                loader: usersLoader(queryClient),
              },
              {
                path: createRoutes.TermsConditions,
                element: <TermsPage />,
                loader: termsPageLoader(queryClient),
              },
              {
                path: createRoutes.Systems,
                element: <SystemsListPage />,
                loader: systemsListLoader(queryClient),
              },
              {
                path: createRoutes.System,
                loader: metricsSystemsLoader(queryClient),
                children: [
                  {
                    path: createRoutes.Product,
                    children: [
                      {
                        path: createRoutes.Advanced,
                        element: <AdvancedPage />,
                        loader: advancedLoader(queryClient),
                      },
                    ],
                  },
                  {
                    path: createRoutes.Metrics,
                    loader: metricsLoader(queryClient),
                    element: <MetricsPage />,
                  },
                ],
              },
              {
                path: createRoutes.System,
                children: [
                  {
                    path: createRoutes.Reservoir,
                    children: [
                      {
                        path: createRoutes.DynamicInfo,
                        element: <ReservoirDynamicInfoPage />,
                        loader: resevoirDynamicInfoLoader(queryClient),
                      },
                    ],
                  },
                ],
              },
              {
                path: createRoutes.SystemCreate,
                element: <SystemCreatePage />,
                loader: systemCreatePageLoader(queryClient),
              },
              {
                path: createRoutes.Issues,
                loader: issuesLoader(queryClient),
                element: <IssuesPage />,
              },
              {
                path: createRoutes.Statistics,
                loader: statisticsPageLoader(queryClient),
                element: <StatisticsPage />,
              },
              {
                path: createRoutes.Profile,
                element: <ProfilePage />,
                loader: profilePageLoader(queryClient),
              },
              {
                path: createRoutes.Support,
                element: <SupportPage />,
              },
              {
                path: createRoutes.NotFound,
                element: <NotFoundPage />,
              },
              {
                path: createRoutes.Unauthorized,
                element: <UnAuthorizedPage />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export async function createRouter() {
  return wrapCreateBrowserRouterV7(createBrowserRouter)(routesConfig);
}
