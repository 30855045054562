import {
  Metric,
  MetricType,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import { TFunction } from 'i18next';
import { SeriesXrangeOptions, XrangePointOptionsObject } from 'highcharts';
import { EventConveyorSystemModeValue } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/event_pb';
import { DataPoint, mapDataPoint } from '~/shared/models/metrics/metrics-utils';
import { getSensorReadingLabel } from '~/shared/models/metrics/metric-conversions';

export const SYSTEM_MODE_CHART_HEIGHT = 40;

export class SystemModeMetricModel {
  type: MetricType = MetricType.SYSTEM_MODE;

  chartData: XrangePointOptionsObject[] = [];

  constructor(metric: Metric) {
    const dataPoints = metric.dataPoints.map((dataPoint) =>
      mapDataPoint(dataPoint),
    );

    this.chartData = SystemModeMetricModel.prepareXRangeData(dataPoints);
  }

  private static prepareXRangeData(
    data: DataPoint[],
  ): XrangePointOptionsObject[] {
    const result: XrangePointOptionsObject[] = [];
    let previousValue: null | number = null;

    data.forEach((point) => {
      const [time, value] = point;

      if (value === 0) {
        return;
      }

      if (value !== previousValue) {
        if (previousValue !== null) {
          result[result.length - 1].x2 = time;
        }

        result.push({
          x: time,
          x2: undefined,
          y: 0,
          name: value.toString(),
          color: SystemModeMetricModel.getSystemModeColor(value),
        });

        previousValue = value;
      }
    });

    if (result.length > 0 && result[result.length - 1].x2 === undefined) {
      const [lastTime] = data[data.length - 1];
      result[result.length - 1].x2 = lastTime;
    }

    return result;
  }

  private static getSystemModeColor(
    value: EventConveyorSystemModeValue,
  ): string {
    switch (value) {
      case EventConveyorSystemModeValue.OUTSIDE_GROWING:
        return '#FFA07A';
      case EventConveyorSystemModeValue.GROWING:
        return '#90EE90';
      case EventConveyorSystemModeValue.FREEZING_PROTECTION:
        return '#ADD8E6';
      case EventConveyorSystemModeValue.MANUAL:
        return '#FFD700';
      default:
        return '#D3D3D3';
    }
  }

  private static getSystemModeLabel(
    t: TFunction,
    value: EventConveyorSystemModeValue,
  ): string {
    switch (value) {
      case EventConveyorSystemModeValue.OUTSIDE_GROWING:
        return t('system.mode.outsideGrowing');
      case EventConveyorSystemModeValue.GROWING:
        return t('system.mode.growing');
      case EventConveyorSystemModeValue.FREEZING_PROTECTION:
        return t('system.mode.freezingProtection');
      case EventConveyorSystemModeValue.MANUAL:
        return t('system.mode.manual');
      default:
        return t('system.mode.unspecified');
    }
  }

  getChartLabel({ t }: { t: TFunction }) {
    const label = getSensorReadingLabel(t, this.type);

    return `<span style="color: #353750; font-size: 16px; line-height: 24px">${label}</span>`;
  }

  createSerie(t: TFunction, yIndex: number): SeriesXrangeOptions {
    return {
      type: 'xrange',
      data: this.chartData,
      yAxis: yIndex,
      pointWidth: SYSTEM_MODE_CHART_HEIGHT,
      borderRadius: 0,
      dataLabels: {
        enabled: true,
        formatter() {
          return SystemModeMetricModel.getSystemModeLabel(t, +this.point.name);
        },
        style: {
          fontSize: '14px',
          textOutline: 'none',
          fontFamily: 'Noto Sans',
          fontWeight: 'normal',
        },
      },
      enableMouseTracking: false,
      states: {
        inactive: {
          opacity: 1,
        },
      },
    };
  }
}
