import {
  GetGeneralStatisticsResponse,
  GetGeneralStatisticsResponse_ConnectedArea,
  GetGeneralStatisticsResponse_DevicesSummary,
  GetGeneralStatisticsResponse_IssuesSummary,
  GetGeneralStatisticsResponse_KPIStats,
  GetGeneralStatisticsResponse_SystemsSummary,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';

export class StatisticsModel {
  public readonly connectedArea?: GetGeneralStatisticsResponse_ConnectedArea;

  public readonly systemsSummary?: GetGeneralStatisticsResponse_SystemsSummary;

  public readonly devicesSummary?: GetGeneralStatisticsResponse_DevicesSummary;

  public readonly kpiStats?: GetGeneralStatisticsResponse_KPIStats;

  public readonly issuesSummary?: GetGeneralStatisticsResponse_IssuesSummary;

  constructor(response: GetGeneralStatisticsResponse) {
    this.connectedArea = response.connectedArea;
    this.systemsSummary = response.systemsSummary;
    this.devicesSummary = response.devicesSummary;
    this.kpiStats = response.kpiStats;
    this.issuesSummary = response.issuesSummary;
  }
}
