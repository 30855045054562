import { debugMode } from '~/services/api/debug/debug-toggle';
import { RELEASE_VERSION } from '~/services/environment/environment';

declare global {
  interface Window {
    RELEASE_VERSION: string;
    debugMode: (enable: boolean) => void;
  }
}

window.RELEASE_VERSION = RELEASE_VERSION;
window.debugMode = debugMode;
