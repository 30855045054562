import {
  createConnectTransport,
  createGrpcWebTransport,
} from '@connectrpc/connect-web';
import { authInterceptor } from '~/services/auth/auth-interceptor';
import {
  ENABLE_MOCKS,
  TRANSPORT_URL,
} from '~/services/environment/environment';
import { grpcDebugMiddleware } from '~/services/api/debug/debug-middleware';

const baseUrl = TRANSPORT_URL;

export const transport = ENABLE_MOCKS
  ? createConnectTransport({
      baseUrl,
      interceptors: [authInterceptor],
    })
  : createGrpcWebTransport({
      baseUrl,
      interceptors: [authInterceptor, grpcDebugMiddleware],
    });
