/* eslint-disable no-console */
import { Interceptor } from '@connectrpc/connect';
import { DEBUG_MODE_STORAGE_KEY } from '~/services/api/debug/debug-toggle';

export const grpcDebugMiddleware: Interceptor = (next) => async (req) => {
  const isDebugMode = localStorage.getItem(DEBUG_MODE_STORAGE_KEY) === 'true';

  if (!isDebugMode) {
    return next(req);
  }

  console.groupCollapsed(`[gRPC] ${req.method.name}`);

  console.log('Request:', JSON.stringify(req.message, null, 2));

  try {
    const response = await next(req);
    console.log('Response:', JSON.stringify(response.message, null, 2));
    console.groupEnd();
    return response;
  } catch (error) {
    console.error('Error:', error);
    console.groupEnd();
    throw error;
  }
};
