import {
  Drain,
  Drain_DrainageSystem,
  Drain_Location,
  Product,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/system_pb';
import { TFunction } from 'i18next';
import { TopologyGraph } from '~/shared/models/system/TopologyGraph';
import { idFromName } from '~/shared/models/id-utils';
import { ReservoirModel } from '~/shared/models/system/ReservoirModel';
import { assertUnreachable } from '~/types/assert-type';

export type DrainParameterDetails = {
  title: string;
  value: string | number;
  unit?: string;
};

const DEFAULT_FLOW_RESTRICTION_SPEED = 5;

type ReservoirFormOption = {
  label: string;
  value: string;
  disabled: boolean;
};

export type DrainFormValues = {
  toReservoirName: string | undefined;
  overflowHeight: string;
  flowRestrictionSpeed?: string;
  enableFlowRestriction?: boolean;
  passiveDrainCount?: string;
  drainageSystem: string;
  drainLocation: string;
};

export const drainageSystemTypes = Object.values(Drain_DrainageSystem).filter(
  (value) => typeof value === 'number',
);

export const drainLocationTypes = Object.values(Drain_Location).filter(
  (value) => typeof value === 'number',
);
export class DrainModel {
  readonly id: string;

  readonly name: string;

  readonly fromReservoirId: string;

  readonly fromReservoirName: string;

  readonly toReservoirId: string | undefined;

  readonly toReservoirName: string | undefined;

  readonly productId: string | undefined;

  readonly overflowHeight: number;

  readonly flowRestrictionSpeed?: number;

  readonly pressurePipe: boolean;

  readonly drainageSystem: Drain_DrainageSystem;

  readonly drainLocation: Drain_Location;

  readonly passiveDrainCount?: number;

  readonly canAddValve: boolean;

  readonly canAddPump: boolean;

  readonly canChangePassiveDrainCount: boolean;

  constructor(drainResponse: Drain, product?: Product) {
    this.name = drainResponse.name;
    this.id = idFromName(drainResponse.name);
    this.fromReservoirId = ReservoirModel.extractReservoirId(
      drainResponse.fromReservoirName,
    );
    this.fromReservoirName = drainResponse.fromReservoirName;
    this.toReservoirId = drainResponse.toReservoirName
      ? ReservoirModel.extractReservoirId(drainResponse.toReservoirName)
      : undefined;
    this.toReservoirName = drainResponse.toReservoirName || 'sewer';
    this.productId = product ? idFromName(product.name) : undefined;
    this.overflowHeight = drainResponse.overflowHeight;
    this.flowRestrictionSpeed = drainResponse.flowRestrictionSpeed;
    this.pressurePipe = drainResponse.pressurePipe;
    this.drainageSystem = drainResponse.drainageSystem;
    this.drainLocation = drainResponse.location;
    this.passiveDrainCount = drainResponse.passiveDrainCount;
    const hasMoreThanOnePassiveDrain =
      !!this.passiveDrainCount && this.passiveDrainCount > 1;

    this.canChangePassiveDrainCount = !this.productId && !this.pressurePipe;

    this.canAddValve =
      !this.productId && !hasMoreThanOnePassiveDrain && !this.pressurePipe;
    this.canAddPump =
      !this.productId && !hasMoreThanOnePassiveDrain && this.pressurePipe;
  }

  toFormValues(): DrainFormValues {
    return {
      toReservoirName: this.toReservoirName,
      overflowHeight: this.overflowHeight.toString(),
      flowRestrictionSpeed: (
        this.flowRestrictionSpeed ?? DEFAULT_FLOW_RESTRICTION_SPEED
      ).toString(),
      enableFlowRestriction: Boolean(this.flowRestrictionSpeed),
      drainageSystem: this.drainageSystem.toString(),
      drainLocation: this.drainLocation.toString(),
      passiveDrainCount: this.passiveDrainCount?.toString(),
    };
  }

  toApiRequest(formValues: DrainFormValues) {
    const flowRestrictionSpeed =
      formValues.enableFlowRestriction &&
      formValues.flowRestrictionSpeed !== undefined
        ? parseFloat(formValues.flowRestrictionSpeed)
        : undefined;

    return new Drain({
      name: this.name,
      fromReservoirName: this.fromReservoirName,
      toReservoirName:
        formValues.toReservoirName === 'sewer'
          ? undefined
          : formValues.toReservoirName,
      overflowHeight: parseFloat(formValues.overflowHeight),
      flowRestrictionSpeed,
      pressurePipe: this.pressurePipe,
      drainageSystem: parseFloat(formValues.drainageSystem),
      location: parseFloat(formValues.drainLocation),
      passiveDrainCount: formValues.passiveDrainCount
        ? parseFloat(formValues.passiveDrainCount)
        : undefined,
    });
  }

  reservoirsToConnect(
    topologyGraph: TopologyGraph,
    reservoirs: ReservoirModel[],
    t: TFunction,
  ): ReservoirFormOption[] {
    const filteredReservoirs = reservoirs.filter(
      (reservoir) => reservoir.name !== this.fromReservoirName,
    );

    const resevoirOptions = filteredReservoirs.map((reservoir) => {
      if (this.pressurePipe) {
        return {
          label: reservoir.title,
          value: reservoir.name,
          disabled: false,
        };
      }

      const willCreateCycle = topologyGraph.willCreateCycle(
        this.fromReservoirId,
        reservoir.id,
      );

      const cycleWarning = willCreateCycle
        ? ` - ${t('systemDesign.drain.edit.fields.labels.willCreateCycle')}`
        : '';

      return {
        label: reservoir.title + cycleWarning,
        value: reservoir.name,
        disabled: willCreateCycle,
      };
    });

    resevoirOptions.push({
      label: t('systemDesign.drain.edit.fields.labels.sewer'),
      value: 'sewer',
      disabled: false,
    });

    return resevoirOptions;
  }

  getTypeForReservoir(reservoirId: string) {
    return reservoirId === this.fromReservoirId ? 'source' : 'target';
  }

  static getLabelForDrainageSystem(
    drainageSystem: Drain_DrainageSystem,
    t: TFunction,
  ) {
    switch (drainageSystem) {
      case Drain_DrainageSystem.GRAVITATIONAL:
        return t('systemDesign.drain.edit.fields.drainageSystem.gravitational');
      case Drain_DrainageSystem.SIPHONIC_QUICKSTREAM:
        return t(
          'systemDesign.drain.edit.fields.drainageSystem.siphonicQuickStream',
        );
      case Drain_DrainageSystem.SIPHONIC_UNIVERSAL:
        return t(
          'systemDesign.drain.edit.fields.drainageSystem.siphonicUniversal',
        );
      case Drain_DrainageSystem.UNSPECIFIED:
        return t('systemDesign.drain.edit.fields.drainageSystem.unspecified');
      default:
        return assertUnreachable(drainageSystem);
    }
  }

  static getLabelForDrainLocation(location: Drain_Location, t: TFunction) {
    switch (location) {
      case Drain_Location.MID_DRAIN:
        return t('systemDesign.drain.edit.fields.drainLocation.midDrain');
      case Drain_Location.SIDE_DRAIN:
        return t('systemDesign.drain.edit.fields.drainLocation.sideDrain');
      case Drain_Location.UNSPECIFIED:
        return t('systemDesign.drain.edit.fields.drainLocation.unspecified');
      default:
        return assertUnreachable(location);
    }
  }

  createDrainParameterDetails(
    t: TFunction,
    showAdditionalData: boolean,
  ): DrainParameterDetails[] {
    const details: DrainParameterDetails[] = [];

    details.push({
      title: t('systemDesign.drain.edit.fields.labels.overflowHeight'),
      value: this.overflowHeight,
      unit: 'mm',
    });

    if (this.canChangePassiveDrainCount && this.passiveDrainCount) {
      details.push({
        title: t('systemDesign.drain.edit.fields.labels.passiveDrainCount'),
        value: this.passiveDrainCount,
      });
    }

    if (!showAdditionalData) {
      return details;
    }

    if (this.flowRestrictionSpeed) {
      details.push({
        title: t('systemDesign.drain.edit.fields.labels.flowRestrictionSpeed'),
        value: this.flowRestrictionSpeed,
        unit: 'mm/h',
      });
    }

    if (!this.pressurePipe) {
      details.push({
        title: t('systemDesign.drain.edit.fields.labels.drainageSystem'),
        value: DrainModel.getLabelForDrainageSystem(this.drainageSystem, t),
      });

      details.push({
        title: t('systemDesign.drain.edit.fields.labels.drainLocation'),
        value: DrainModel.getLabelForDrainLocation(this.drainLocation, t),
      });
    }

    return details;
  }
}
