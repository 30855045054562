import { getStatistics } from '~/pages/StatisticsPage/api/statistics.api';
import { StatisticsModel } from '~/pages/StatisticsPage/models/StatisticsModel';
import { makeQuery } from '~/shared/api/internal/utils';

export const statisticsQuery = makeQuery({
  queryKey: ['statistics'],
  queryFn: async () => {
    const data = await getStatistics();

    return new StatisticsModel(data);
  },
});
