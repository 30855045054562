import {
  Metric,
  MetricType,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import { TFunction } from 'i18next';
import {
  DataPoint,
  getOutdatedReadingLabel,
  isMetricReadingOutdated,
  mapDataPoint,
} from '~/shared/models/metrics/metrics-utils';
import {
  getSensorReadingLabel,
  getUnit,
  mapMetricValue,
  Unit,
} from '~/shared/models/metrics/metric-conversions';

export class MetricModel {
  readonly resourceName: string;

  readonly type: MetricType;

  readonly lastDataPoint: DataPoint | undefined = undefined;

  readonly unit: Unit | undefined;

  constructor(metric: Metric) {
    this.resourceName = metric.resourceName;
    this.type = metric.type;

    const lastMetricDataPoint = metric.dataPoints.at(-1);

    if (lastMetricDataPoint) {
      this.lastDataPoint = mapDataPoint(lastMetricDataPoint);
    }

    this.unit = getUnit(this.type);
  }

  getLastValue(t: TFunction): string | number | undefined {
    const lastValue = this.getLastNumberValue();
    if (lastValue === undefined) {
      return undefined;
    }

    return mapMetricValue(t, this.type, lastValue);
  }

  getLastNumberValue(): number | undefined {
    return this.lastDataPoint?.[1] ?? undefined;
  }

  getLatestTimestampLabel(t: TFunction) {
    const { lastDataPoint } = this;

    if (!lastDataPoint) {
      return null;
    }

    if (!isMetricReadingOutdated(lastDataPoint[0])) {
      return null;
    }

    return getOutdatedReadingLabel(t, lastDataPoint[0]);
  }

  getLabel = (t: TFunction) => getSensorReadingLabel(t, this.type);
}
