import { Grid } from '@mui/material';
import { QueryClient } from '@tanstack/react-query';
import { Suspense } from 'react';
import { Outlet } from 'react-router';
import { RenderDrawerContentProps } from '~/pages/layout/components/Navigation/Drawer';
import { DrawerContainer } from '~/pages/layout/components/Navigation/DrawerContainer';
import { loadAuthUser } from '~/shared/auth/auth-utils';
import { Snackbar } from '~/shared/components/Snackbar/Snackbar';
import { DRAWER_WIDTH } from './layout-constants';

export const rootLoader = (queryClient: QueryClient) => async () => {
  await loadAuthUser(queryClient);
  return null;
};

export function AppLayout({ renderDrawerContent }: RenderDrawerContentProps) {
  return (
    <Grid container>
      <DrawerContainer renderDrawerContent={renderDrawerContent} />

      <Grid
        container
        flexDirection="column"
        flexWrap="nowrap"
        minHeight="100vh"
        width={{
          xs: '100%',
          lg: `calc(100% - ${DRAWER_WIDTH}px)`,
        }}
        pb={5}
        pt={{ xs: 10, sm: 12, lg: 5 }}
        px={{
          xs: 2,
          md: 4,
          lg: 8,
        }}
      >
        {/*
          No fallback because we don't want to show a flash or spinner.
          The navigation will remain visible.
        */}
        <Suspense>
          <Outlet />
        </Suspense>

        <Snackbar />
      </Grid>
    </Grid>
  );
}
