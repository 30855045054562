import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router';
import { statisticsQuery } from '~/pages/StatisticsPage/api/statistics.queries';
import { StatisticsModel } from '~/pages/StatisticsPage/models/StatisticsModel';
import { checkAuthorization, requireUserRole } from '~/shared/auth/auth-utils';

export type StatisticsPageLoaderData = {
  statistics: StatisticsModel;
};

export const statisticsPageLoader =
  (queryClient: QueryClient) =>
  async ({
    request,
  }: LoaderFunctionArgs): Promise<StatisticsPageLoaderData | Response> => {
    await checkAuthorization(queryClient, request);

    await requireUserRole(queryClient, request, [
      'internal_admin',
      'internal_engineer',
    ]);

    const statistics = await queryClient.ensureQueryData(statisticsQuery);

    return { statistics };
  };
