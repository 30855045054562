import type { ComponentsOverrides, PaletteColor, Theme } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { createTheme, useTheme } from '@mui/material/styles';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import {
  ChevronDownIcon,
  InformationCircleIcon,
} from '~/shared/components/Icons/Icons';
import LoraMedium from '~/shared/theme/internal/fonts/lora/Lora-Medium.ttf';
import LoraSemiBold from '~/shared/theme/internal/fonts/lora/Lora-SemiBold.ttf';
import NotoSansRegular from '~/shared/theme/internal/fonts/notosans/NotoSans-Regular.ttf';
import NotoSansMedium from '~/shared/theme/internal/fonts/notosans/NotoSans-Medium.ttf';

import { createShadows } from '~/shared/theme/internal/shadows';

const supportiveMainRgb = '65, 64, 66';

// This is the only workaround to be able to modify root styling of MuiSelect
// https://github.com/mui/material-ui/issues/30225
type OverridesWithRoot = ComponentsOverrides<Theme>['MuiSelect'] & {
  root: OverridesStyleRules<'root', 'MuiSelect', Theme>['root'];
};

const bodyS = {
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '140%',
};

const bodyXS = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '140%',
};

// Disabled to allow shadowing of `theme` for style overrides
/* eslint-disable @typescript-eslint/no-shadow */
export const theme = createTheme({
  palette: {
    background: {
      paper: '#FFFFFF',
      default: '#F5F5F5',
    },
    primary: {
      dark: '#353750',
      main: '#65677A',
      light: '#9596A2',
      lightest: '#C5C5CC',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#00A8E1',
      lightest: '#B9E9FE',
      contrastText: '#FFFFFF',
    },
    supportive: {
      dark: '#414042',
      main: '#6E6D6F',
      light: '#A09fA0',
      lightest: '#CFCFD0',
    },
    error: {
      main: '#FF7D5E',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FDBA63',
    },
    info: {
      main: '#62AF81',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#62AF81',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#353750',
      secondary: '#9596A2',
      disabled: '#C5C5CC',
    },
    divider: `rgba(${supportiveMainRgb}, .12)`,
    action: {
      active: `rgba(${supportiveMainRgb}, .54)`,
      hover: `rgba(${supportiveMainRgb}, .1)`,
      selected: `rgba(${supportiveMainRgb}, .08)`,
      disabled: `rgba(${supportiveMainRgb}, .26)`,
      disabledBackground: `rgba(${supportiveMainRgb}, .12)`,
      focus: `rgba(${supportiveMainRgb}, .12)`,
    },
  },
  border: {
    size: 1,
    radius: 10,
  },
  shadows: createShadows(supportiveMainRgb),
  typography: {
    fontFamily: 'Noto Sans',
    allVariants: {
      color: '#353750',
    },
    button: {
      textTransform: 'none',
    },
    'display-xl': {
      fontFamily: 'Lora',
      fontSize: 80,
      fontWeight: 600,
      lineHeight: '100%',
      letterSpacing: '-2%',
    },
    'display-l': {
      fontFamily: 'Lora',
      fontSize: 64,
      fontWeight: 600,
      lineHeight: '100%',
      letterSpacing: '-2%',
    },
    'display-m': {
      fontFamily: 'Lora',
      fontSize: 48,
      fontWeight: 600,
      lineHeight: '100%',
      letterSpacing: '-1%',
    },
    'display-s': {
      fontFamily: 'Lora',
      fontSize: 32,
      fontWeight: 500,
      lineHeight: '100%',
      letterSpacing: '-1%',
    },
    'display-xs': {
      fontFamily: 'Lora',
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '100%',
    },
    'heading-xl': {
      fontSize: 40,
      fontWeight: 400,
      lineHeight: '130%',
    },
    'heading-l': {
      fontSize: 36,
      fontWeight: 400,
      lineHeight: '130%',
    },
    'heading-m': {
      fontSize: 28,
      fontWeight: 400,
      lineHeight: '130%',
    },
    'body-xl': {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: '140%',
    },
    'body-l': {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: '140%',
    },
    'body-m': {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '140%',
    },
    'body-s': bodyS,
    body1: bodyS,
    'body-xs': bodyXS,
    body2: bodyXS,
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          'display-xl': 'h1',
          'display-l': 'h1',
          'display-m': 'h1',
          'display-s': 'h1',
          'display-xs': 'h2',

          'heading-xl': 'span',
          'heading-l': 'span',
          'heading-m': 'span',

          'body-xl': 'p',
          'body-l': 'p',
          'body-m': 'p',
          'body-s': 'p',
          'body-xs': 'p',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Lora';
          font-weight: 600;
          src: local('Lora SemiBold'), url(${LoraSemiBold});
        }
        @font-face {
          font-family: 'Lora';
          font-weight: 500;
          src: local('Lora Medium'), url(${LoraMedium});
        }
        @font-face {
          font-family: 'Noto Sans';
          font-weight: 400;
          src: local('Noto Sans Regular'), url(${NotoSansRegular});
        }
        @font-face {
          font-family: 'Noto Sans';
          font-weight: 500;
          src: local('Noto Sans Medium'), url(${NotoSansMedium});
        }
      `,
    },
    MuiButton: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(3),
          boxShadow: 'none',
          alignSelf: 'center',
          whiteSpace: 'nowrap',
          '&:hover': {
            boxShadow: 'none',
          },
        }),
        sizeLarge: ({ theme }) => ({
          padding: `${theme.spacing(2)} ${theme.spacing(3)}}`,
        }),
        sizeMedium: ({ theme }) => ({
          padding: `${theme.spacing(1.5)} ${theme.spacing(3)}}`,
        }),
        sizeSmall: ({ theme }) => ({
          padding: `${theme.spacing(1)} ${theme.spacing(3)}}`,
        }),
        contained: ({ theme, ownerState }) => ({
          backgroundColor: ownerState.color
            ? ownerState.color
            : theme.palette.secondary.main,
        }),
        outlined: ({ theme }) => ({
          color: theme.palette.primary.dark,
          borderColor: theme.palette.primary.dark,
        }),
        text: ({ theme }) => ({
          color: theme.palette.primary.dark,
          textDecoration: 'underline',
        }),
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterDelay: 300,
      },
      styleOverrides: {
        arrow: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
        tooltip: ({ theme }) => ({
          fontSize: theme.typography['body-xs'].fontSize,
          backgroundColor: theme.palette.text.primary,
        }),
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ChevronDownIcon,
      },
      styleOverrides: {
        select: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.contrastText,
          borderRadius: theme.spacing(1.5),
          border: 'none',
          width: '100%',
          padding: theme.spacing(2),
          p: {
            maxWidth: theme.spacing(42),
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
        }),
        icon: ({ theme }) => ({
          fill: theme.palette.text.primary,
          fontSize: theme.spacing(2),
          right: theme.spacing(2),
        }),
        root: ({ theme }) =>
          theme.unstable_sx({
            outline: 'none',
            width: '100%',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '& .MuiNativeSelect-select': {
              border: 'none',
              borderRadius: theme.spacing(1.5),
            },
          }),
      } as OverridesWithRoot,
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
          '&&': {
            backgroundColor: theme.palette.background.paper,
            border: 'none',
            borderRadius: theme.spacing(1.5),
          },
          '& fieldset': {
            border: 'none',
          },
        }),
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        multiline: {
          paddingTop: 2,
          paddingBottom: 2,
          borderRadius: 1.5,
        },
        root: ({ theme }) => ({
          '&&.MuiInputBase-root': {
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.spacing(1.5),
          },
          '&& .MuiSelect-select': {
            backgroundColor: theme.palette.background.default,
          },
          '& .MuiInputBase-input.MuiInputBase-inputSizeSmall': {
            paddingTop: `${theme.spacing(1)}`,
            paddingBottom: `${theme.spacing(1)}`,
          },
          '& .MuiInputBase-input': {
            paddingTop: `${theme.spacing(2)}`,
            paddingBottom: `${theme.spacing(2)}`,
            borderRadius: theme.spacing(1.5),
          },
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          width: '100%',
          padding: theme.spacing(2, 3, 2, 3),
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          '&:not(:last-of-type)': {
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
          '&.Mui-selected': {
            fontWeight: theme.typography.fontWeightBold,
          },
        }),
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          success: <InformationCircleIcon />,
          error: <InformationCircleIcon />,
          info: <InformationCircleIcon />,
          warning: <InformationCircleIcon />,
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.paper,
          width: 'max-content',
          maxWidth: '100%',
          alignItems: 'center',
          borderRadius: theme.spacing(12),
          padding: theme.spacing(1, 3),
        }),
        message: ({ theme }) => ({
          fontSize: theme.typography['body-s'].fontSize,
          lineHeight: theme.typography['body-s'].lineHeight,
          padding: 0,
        }),
        action: ({ theme }) => ({
          padding: 0,
          marginLeft: theme.spacing(2),
        }),
        standardInfo: ({ theme }) => ({
          color: theme.palette.info.main,
        }),
        standardSuccess: ({ theme }) => ({
          color: theme.palette.success.main,
        }),
        standardWarning: ({ theme }) => ({
          color: theme.palette.primary.dark,
          '& .MuiAlert-icon': {
            color: theme.palette.primary.dark,
          },
        }),
        standardError: ({ theme }) => ({
          color: theme.palette.error.main,
        }),
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: ({ theme }) => ({
          boxShadow: theme.shadows[1],
          borderRadius: theme.border.radius,
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          maxHeight: `calc(100% - ${theme.spacing(2)})`,
          width: '100%',
          margin: theme.spacing(2),
        }),
      },
    },
  },
});

export type AppTheme = Theme & {
  palette: {
    primary: PaletteColor & { lightest: string };
    secondary: PaletteColor & { lightest: string };
    supportive: PaletteColor & { lightest: string };
  };
};

export function useAppTheme(): AppTheme {
  return useTheme<AppTheme>();
}
