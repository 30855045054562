import { Grid, Typography } from '@mui/material';
import { Dot } from '../Dot/Dot';

type DotLabelProps = {
  label: string;
  htmlFor?: string;
  id?: string;
};

export function DotLabel({ label, htmlFor, id }: DotLabelProps) {
  return (
    <Grid gap={1} container alignItems="center">
      <Dot />
      <Typography variant="body-xs" htmlFor={htmlFor} id={id} component="label">
        {label}
      </Typography>
    </Grid>
  );
}
