import { DeviceDetailsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import {
  Connectivity,
  Connectivity_Status,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/connectivity_pb';
import { Link, Theme } from '@mui/material';
import { TFunction } from 'i18next';
import { assertIsDefined } from '~/types/assert-type';

export type Detail = {
  label?: string;
  value?: React.ReactNode;
  color?: string;
};

export class DeviceDetailsModel {
  connectivity?: Connectivity;

  serialNumber?: string;

  externalLink?: string;

  externalLinkText?: string;

  externalLinkLabel?: string;

  isFromParticleIo: boolean;

  constructor(deviceDetailsResponse?: DeviceDetailsResponse | null) {
    this.connectivity = deviceDetailsResponse?.connectivity;

    this.serialNumber = deviceDetailsResponse?.serialNumber;

    this.externalLink = deviceDetailsResponse?.externalLink;

    this.externalLinkText = deviceDetailsResponse?.externalLinkText;

    this.externalLinkLabel = deviceDetailsResponse?.externalLinkLabel;

    this.isFromParticleIo =
      deviceDetailsResponse?.connectivity?.status !==
      Connectivity_Status.UNSPECIFIED;
  }

  getConnectivityDetails(theme: Theme, t: TFunction): Detail[] {
    assertIsDefined(this.connectivity);

    const {
      latestMetricsReceived,
      status,
      batteryPercentage,
      signalStrengthPercentage,
      signalStrengthDbm,
      network,
    } = this.connectivity;

    const details: Detail[] = [
      {
        label: t('device.connectivity.labels.latestDataDate'),
        value: latestMetricsReceived?.toDate().toUTCString() ?? 'unknown',
      },
    ];

    if (!this.isFromParticleIo) {
      return details;
    }

    details.push(
      {
        label: t('device.connectivity.labels.status'),
        value: DeviceDetailsModel.getConnectivityStatus(status, theme).label,
        color: DeviceDetailsModel.getConnectivityStatus(status, theme).color,
      },
      {
        label: t('device.connectivity.labels.battery'),
        value: `${batteryPercentage}%`,
      },
      {
        label: t('device.connectivity.labels.signalStrenth'),
        value: `${signalStrengthPercentage}%`,
      },
      {
        label: t('device.connectivity.labels.signalStrenthDbm'),
        value: `${Number(signalStrengthDbm)} dBm`,
      },
      {
        label: t('device.connectivity.labels.network'),
        value: network,
      },
    );

    return details;
  }

  getHardwareInfo(t: TFunction): Detail[] {
    return [
      {
        label: t('device.hardware.labels.serial'),
        value: this.serialNumber || '---',
      },
      {
        label: this.externalLinkLabel,
        value: <Link href={this.externalLink}>{this.externalLinkText}</Link>,
      },
    ];
  }

  private static getConnectivityStatus = (
    ConnectivityStatus: Connectivity_Status,
    theme: Theme,
  ) => {
    switch (ConnectivityStatus) {
      case Connectivity_Status.OK:
        return {
          label: 'OK',
          color: theme.palette.success.main,
        };
      default:
        return {
          label: 'Unknown',
          color: theme.palette.error.main,
        };
    }
  };
}
